import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { LibAngularApiModule } from '../../projects/lib-angular-api/src/lib/lib-angular-api.module';
import { LoggingModule } from './services/logging/logging.module';

const config = require('../assets/config.json');

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, HttpClientModule, LibAngularApiModule.forRoot(), LoggingModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
