import { dmConfig } from './deviceMgrConfig';
import { deviceIds, DeviceManager, TraceLevels } from './embross-device-manager';
import { EventEmitter } from '@angular/core';

export class DeviceMgrInitialize {}

// let emulateCUSS = true   // true - no need to run CUSS
let devMgr = null;
let kioskId = null;
const systemManagerKioskId = null;
const actualCUSSKioskId = null;
let airportCode = null;
let location = null;
let CompanyCode = null;
let appName = null;
let currentState = null;
export const socketAlive = new EventEmitter<boolean>();
export const almostOutOfTimeEvent = new EventEmitter<boolean>();
export const notificationTextEvent = new EventEmitter<string>();
export const notifyActiveEvent = new EventEmitter<boolean>();
export const loadMachineId = new EventEmitter<any>();

devMgr = new DeviceManager('127.0.0.1', dmConfig.port, dmConfig.noCUSS, dmConfig.defaultAirportCode);
// setup application manager
devMgr.setAppManager(appMgrOnChange, appMgrOnActive, appMgrOnEvent);

function OnConnected() {
  socketAlive.emit(true);
  // INITIALIZE applog
  if (devMgr.setLogger('appManager', true)) {
    // default log file
    devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'DeviceManager opened');
    const version = navigator.appVersion;
    devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, '***** Browser: ' + version);
  } else {
    devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'appManager log not initialized!!!');
  }
  // send version
  devMgr.appManager.sendVersion('1.0.0', true);
}

function OnSocketOpened(restarting) {
  if (restarting) {
    devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'OnSocketOpened - restarting');
  } else {
    devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'OnSocketOpened');
    if (dmConfig.noCUSS) {
      devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, '**************  no CUSS connection **********');
    } else {
      devMgr.appManager.available();
    }
  }
}

function onActive(evt) {
  devMgr.sendLogMsg(TraceLevels.LOG_TRACE, 'onActive - main evt: ' + evt.key + ' ' + evt.value);
  // continue notification if active is true
  if (evt.value) {
    appMgrOnActive();
    getDeviceManager()
      .getDevice(deviceIds.ATB_PRINTER)
      .enable();
    getDeviceManager()
      .getDevice(deviceIds.BARCODE_READER)
      .enable();
    getDeviceManager()
      .getDevice(deviceIds.PASSPORT_READER)
      .enable();
    getDeviceManager()
      .getDevice(deviceIds.BAGTAG_PRINTER)
      .enable();
  }
}

devMgr.onConnected = OnConnected;
devMgr.appManager.onSocketOpened = OnSocketOpened;
devMgr.appManager.onActive = onActive;
devMgr.connect();

export function getDeviceManager() {
  return devMgr;
}

export function appMgrOnChange(change) {
  devMgr.sendLogMsg(
    TraceLevels.LOG_TRACE,
    'appMgrOnChange() is called from client: ' + change.key + ' value ' + change.value
  );
  getKioskProperties(change.key, change.value);
}

export function appMgrOnActive() {
  notifyActiveEvent.emit(true);
  devMgr.sendLogMsg(TraceLevels.LOG_TRACE, 'appMgrOnActive called');
}

// start ETS transaction for non PSAM apps when notification text arrives
export function appMgrOnEvent(event) {
  devMgr.sendLogMsg(
    TraceLevels.LOG_EXT_TRACE,
    'appMgrOnEvent() is called from client: ' + event.key + ' ' + event.value
  );
  if (event.key === 'notificationText') {
    devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'notificationText: event.value ' + event.value);
    notificationTextEvent.emit(event.value);
  } else if (event.key === 'almostOutOfTime') {
    devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'almostOutOfTime: event.value ' + event.value);
    almostOutOfTimeEvent.emit(true);
  } else if (event.key === 'notifyActive') {
    devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'notifyActive: event.value ' + event.value);
    notifyActiveEvent.emit(event.value);
  }
}

export function passportReaderOnEvent(event) {
  devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'passportReaderOnEvent() called ... event : ' + JSON.stringify(event));
}

export function barcodeReaderOnEvent(event) {
  devMgr.sendLogMsg(TraceLevels.LOG_EXT_TRACE, 'barcodeReaderOnEvent() called ... event : ' + JSON.stringify(event));
}

export function ATBPrinterOnEvent(event) {
  devMgr.sendLogMsg('ATBPrinterOnEvent() called ... event : ' + JSON.stringify(event));
}

export function BagtagPrinterOnEvent(event) {
  devMgr.sendLogMsg('BagtagPrinterOnEvent() called ... event : ' + JSON.stringify(event));
}

export function cardReaderOnEvent(event) {
  devMgr.sendLogMsg('cardReaderOnEvent() called ... event : ' + JSON.stringify(event));
}

export function getKioskProperties(key, value) {
  if (key === 'kioskId' && value) {
    kioskId = value;
    loadMachineId.emit(value);
  } else if (key === 'airportCode' && value != null) {
    airportCode = value;
  } else if (key === 'getCurrentState' && value != null) {
    currentState = value;
  } else if (key === 'location' && value != null) {
    location = value;
  } else if (key === 'companyCode' && value != null) {
    CompanyCode = value;
  } else if (key === 'appName' && value != null) {
    appName = value;
  }
}

export function getKioskId() {
  return kioskId;
}

export function getSystemManagerKioskId() {
  return systemManagerKioskId;
}

export function getActualCUSSKioskId() {
  return actualCUSSKioskId;
}

export function getAirportCode() {
  return airportCode;
}

export function getLocation() {
  return location;
}

export function getCompanyCode() {
  return CompanyCode;
}

export function getAppName() {
  return appName;
}
