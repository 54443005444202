import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import { InsightsLogger } from './insights-logger.service';
import { AppInsightsService, ApplicationInsightsModule } from '@markpieszak/ng-application-insights';
import { Logger } from './logger.service';
import { Logging } from './logging.service';
import { ConsoleLogger } from './console-logger.service';



let loggerClass: any = InsightsLogger;
if (environment.name === 'local') {
  loggerClass = ConsoleLogger;
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ApplicationInsightsModule.forRoot({
      instrumentationKey: environment.logging.instrumentationKey,
      url: '/assets/js/insights-sdk.js',
    }),
  ],
  providers: [{ provide: Logger, useClass: loggerClass }, Logging, AppInsightsService],
})
export class LoggingModule {}
