import { Injectable } from '@angular/core';
import { Logger } from './logger.service';
import { LogEvent } from './log-event.interface';

@Injectable({
  providedIn: 'root',
})
export class Logging {
  private readonly sessionTime: number;

  constructor(private loggingBackend: Logger) {
    this.sessionTime = new Date().getTime();
  }

  logWithThisInfo(info: LogEvent) {
    const event: LogEvent = {
      message: info.message,
      environment: info.environment,
      kioskId: info.kioskId,
      attemptNumber: info.attemptNumber,
      time: info.time,
      isAPISuccessful: info.isAPISuccessful,
    };

    const withInfo = { ...event } as LogEvent;

    this.loggingBackend.log(withInfo);
  }
}
