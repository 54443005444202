import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { HeaderTypes } from '../../../../../src/app/models/headers';
import { environment } from '../../../../../src/environments/environment';

@Injectable({ providedIn: 'root' })
export class RequestBuilder {
  queryParams: HttpParams;
  headersArray: HeaderTypes[];
  url: string;
  requestBody: object;
  readonly key = 'key';
  readonly value = 'value';

  constructor(public http: HttpClient, @Inject('API_BASE_URL') url: string) {
    this.url = url;
    this.queryParams = new HttpParams();
  }

  public initializeHeaderState() {
    this.headersArray = new Array<HeaderTypes>();
  }

  /**
   * Will execute a [GET] call on the URL resource provided
   * @return Returns a promise which the client can choose how to handle
   */
  async doGetCall() {
    return await this.doRequest(this.http.get, { url: this.url }).toPromise();
  }

  /**
   * Will set as many headers on the following request as provided
   * @param requestHeaders Object containing headers with key and value
   * @return The same instance of RequestBuilder
   */
  setHeaders(requestHeaders: object): RequestBuilder {
    this.initializeHeaderState();
    const { xHaChannel, xHaBusinessDomain, Authorization, xRootCorrelationId } = environment.headers;
    this.headersArray.push([xHaChannel.key, xHaChannel.value]);
    this.headersArray.push([xHaBusinessDomain.key, xHaBusinessDomain.value]);
    this.headersArray.push([Authorization.key, Authorization.value]);
    this.headersArray.push([xRootCorrelationId.key, xRootCorrelationId.value]);
    return this;
  }

  /**
   * Will set as many query parameters to the following request as provided
   * @param queryParams Object containing query params with key and value
   * @return The same instance of RequestBuilder
   */
  setQueryParams(queryParams: object): RequestBuilder {
    this.clearQueryParams();
    Object.keys(queryParams).forEach((prop) => {
      this.appendQueryParams(queryParams[prop][this.key], queryParams[prop][this.value]);
    });
    return this;
  }

  /**
   * Will set request body provided
   * @param requestBody Object containing valid JSON
   * @return The same instance of RequestBuilder
   */
  setBody(requestBody: object): RequestBuilder {
    this.requestBody = requestBody;
    return this;
  }

  private clearQueryParams() {
    this.getQueryParamsCount().forEach((qp) => {
      this.queryParams = this.queryParams.delete(qp);
    });
  }

  private getQueryParam(key: string) {
    return this.queryParams.get(key);
  }

  private getQueryParamsCount() {
    return this.queryParams.keys();
  }

  private appendQueryParams(key: string, value: string) {
    const qpValue = this.getQueryParam(key);
    if (qpValue === null && value != null && value.length > 0) {
      this.queryParams = this.queryParams.set(key, value);
    }
  }

  /**
   * Calls and returns http response call by setting its http verb method and additional data to be sent
   * @param httpFn - The http verb function to be used
   * @param httpData -A custom object which has the following attributes: url<string>, bodyData<any>.
   * These attributes will be set on the http verb method invocation
   */
  private doRequest(httpFn, httpData: { url: string; bodyData?: any }) {
    const completeHeaders = this.createHeaders(this.headersArray);
    const { url } = httpData;
    const httpOptions: any = {
      headers: completeHeaders,
      observe: 'response'
    };

    if (this.queryParams.keys().length > 0) {
      httpOptions.params = this.queryParams;
    }

    let observable;
    if (this.requestBody) {
      observable = httpFn.call(this.http, url, this.requestBody, httpOptions);
    } else {
      observable = httpFn.call(this.http, url, httpOptions);
    }

    return observable.pipe(
      map((response: HttpResponse<any>) => response.body),
      catchError((err) => {
        return of(err);
      })
    );
  }

  private createHeaders(headerValues) {
    let headers = new HttpHeaders();
    for (const [key, value] of headerValues) {
      headers = headers.append(key, value);
    }
    return headers;
  }
}
