import { Injectable } from '@angular/core';
import { Logger } from './logger.service';
import { LogEvent } from './log-event.interface';

@Injectable({
  providedIn: 'root'
})
export class ConsoleLogger extends Logger {
  constructor() {
    super();
  }

  log(event: LogEvent) {
    const color = 'black';

    console.log(`%c${'LOG_LEVEL'} ${event.message}:`, `color: ${color}; font-size:14px;`);
    console.log(event);
  }
}
