import { Injectable } from '@angular/core';
import { LogEvent } from './log-event.interface';

@Injectable({
  providedIn: 'root',
})
export class Logger {
  constructor() {}

  log(event: LogEvent) {}
}
