// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


import { HeaderKeys } from '../app/services/config/api.config';

export const environment = {
  production: false,
  name: 'test',
  logging: {
    level: 'DEBUG',
    environmentName: 'TEST',
    applicationName: 'CEKCI',
    domain: 'DoT Check-in',
    apiKey: 'yyvlobkaeyuvx3c0h0x7jywist1ctecbhrbdgpc8',
    instrumentationKey: 'b5298cad-a98c-423f-89ba-8912e141f132',
  },
  api: {
    basePath: 'kiosk-test.nonprod.itservices.hawaiianairlines.com',
    isMule: true,
    env: 'test',
    endpoints: {
      exp: 'exp-cx-config-admin'
    },
    apiPaths: {
      launcher: 'launcher'
    },
    apiQParams: {
      machineId: 'machineId'
    },
    version: 'v1'
  },
  local_api: {
    host: '10.0.2.2',
    port: 3000
  },
  headers: {
    Authorization: {
      key: HeaderKeys.Authorization,
      value: 'BASIC NzBjMWY4MTI4MjM5NGNlZjg5ODYyOTU4YmI5MmM5NjI6MUEwMjVEOTBFMjcwNDA1NUI0MDYzM0RjYUU2MTcxODQ='
    },
    xHaBusinessDomain: {
      key: HeaderKeys.XHaBusinessDomain,
      value: 'CHECKIN'
    },
    xHaChannel: {
      key: HeaderKeys.XHaChannel,
      value: 'WEB'
    },
    contentType: {
      key: HeaderKeys.ContentType,
      value: 'application/json'
    },
    xRootCorrelationId: {
      key: HeaderKeys.XRootCorrelationId,
      value: Math.ceil(Math.random() * 100000) + ''
    },
    xRootSessionId: {
      key: HeaderKeys.XRootSessionId,
      value: ''
    }
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
