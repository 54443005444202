import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { LibAngularApiService } from './lib-angular-api.service';
import { LibAngularApiComponent } from './lib-angular-api.component';

@NgModule({
  declarations: [LibAngularApiComponent],
  imports: [HttpClientModule],
  exports: [LibAngularApiComponent]
})
export class LibAngularApiModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: LibAngularApiModule,
      providers: [LibAngularApiService, [{ provide: 'API_BASE_URL', useValue: '' }]]
    };
  }
}
