import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestBuilder } from './core/request-builder.service';

@Injectable()
export class LibAngularApiService {
  constructor(private http: HttpClient) {}

  /**
   *
   * @param url string used for connecting to a resource
   * @return a new instance of RequestBuilder
   */
  request(url: string): RequestBuilder {
    return new RequestBuilder(this.http, url);
  }
}
