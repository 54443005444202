import { Component, OnDestroy, OnInit } from '@angular/core';
import { loadMachineId } from './embross/deviceMgrInitialize';
import { LaunchUrlModel } from './models/launchUrl.model';

import { environment } from '../environments/environment';
import { LibAngularApiService } from '../../projects/lib-angular-api/src/lib/lib-angular-api.service';
import { Logging } from './services/logging/logging.service';
import { LogEvent } from './services/logging/log-event.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'checkin-angular-humu-launcher';
  private delay = 2000;
  private machineId: string;
  private responseObject: LaunchUrlModel;
  private machineId$: any;
  private regexMatch = '.kci-(green|blue).(a|test|dev).*(hawaiian|hawaiianair)';
  private launchKey = 'launch-url';
  private attemptNumber = 0;

  constructor(private service: LibAngularApiService, private logging: Logging) {}

  ngOnInit(): void {
    this.start();
  }

  ngOnDestroy(): void {
    if (this.machineId$) {
      this.machineId$.unsubscribe();
    }
  }

  start() {
    this.machineId$ = loadMachineId.subscribe((machineId) => {
      this.machineId = machineId;
      this.doTask();
    });
  }

  exponentialBackoff(delay) {
    this.service
      .request(this.buildMuleUrl())
      .setHeaders(this.buildHeaders())
      .setQueryParams(this.getQueryParams())
      .doGetCall()
      .then((response) => {
        this.responseObject = response;
        this.validateResponseRecursively(delay);
      });
  }

  validateResponseRecursively(delay) {
    this.attemptNumber++;
    if (!this.responseObject) {
      this.reloadAndRetry(delay);
      const environmentUrl = null;
      this.logHandler('FailedRedirect', environmentUrl, this.attemptNumber, false);
    } else if (this.responseObject.status === 200 && this.responseObject[this.launchKey].match(this.regexMatch)) {
      this.windowRedirect();
    } else {
      this.reloadAndRetry(delay);
      this.logHandler('FailedRedirect', this.responseObject[this.launchKey], this.attemptNumber, true);
    }
  }

  reloadAndRetry(delay) {
    setTimeout(() => {
      delay < 60000 ? this.exponentialBackoff((delay + 1000) * 2) : this.exponentialBackoff(delay);
    }, delay);
  }

  doTask() {
    this.exponentialBackoff(this.delay);
  }

  private logHandler(message: string, environmentUrl: string, attemptNumber: number, isAPISuccessful: boolean) {
    this.logging.logWithThisInfo({
      message,
      environment: environmentUrl,
      attemptNumber,
      isAPISuccessful,
      kioskId: this.machineId,
      time: new Date().getTime(),
    });
  }

  private getQueryParams() {
    return [{ key: environment.api.apiQParams.machineId, value: this.machineId }];
  }

  private buildMuleUrl() {
    // tslint:disable-next-line:max-line-length
    return `https://${environment.api.basePath}/${environment.api.endpoints.exp}/${environment.api.version}/api/${environment.api.apiPaths.launcher}`;
  }

  private buildHeaders() {
    return environment.headers;
  }

  windowRedirect() {
    this.logHandler('SuccessfulRedirect', this.responseObject[this.launchKey], this.attemptNumber, true);
    window.location.assign(this.responseObject[this.launchKey]);
  }
}
