import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-api-lib-angular-api',
  template: ``,
  styles: []
})
export class LibAngularApiComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
